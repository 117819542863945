<template>
	<div class="desktopLayoutClass">
		<v-app style="min-height: 80vh !important">

			<div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

			<div>
				<v-row justify="center">
					<v-dialog v-model="ageLimitModal" persistent max-width="290">
					<v-card>
						<v-card-title style="word-break:normal">
						  {{$t("Warning_Message.Invalid_Dependent_Please_Add_Them_Through_My_Linked_Contacts")}}
						</v-card-title>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" depressed @click="closeageLimitModal">
							 {{$t("Warning_Message.Ok")}}
						</v-btn>
						</v-card-actions>
					</v-card>
					</v-dialog>
				</v-row>
			</div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                 {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
				<v-row justify="center">
					<v-dialog v-model="editProfileSuccessModal" max-width="290">
						<v-card>
							<v-card-title style="word-break:normal;margin-top:20px;">
								{{ $t("Customer.EditDependent.Dependent_Edited") }}
							</v-card-title>
							<v-card-text
							></v-card-text
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									align="center"
									color="primary"
									depressed
									@click="closeEditProfileSuccessModal"
									>
									{{$t("Warning_Message.Ok")}}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-row>
			</div>

			<div class="mainDiveditDependent">
				<div
					v-show="show_header"
					class="header"
					style="
					display: flex;
					flex-direction: row;
					padding-top: 10px;
					padding-left: 5px;
					"
					>
					<div style="display: flex; flex-direction: row; width: 100%">
						<div
						style="width: 10%; padding-top: 6px"
						align="left"
						v-on:click="redirectToMyFamily()"
						>
							<img
							src="https://img.icons8.com/material-outlined/20/000000/left.png"
							/>
						</div>
						<div style="width: 90%" align="left">
							<span style="font-weight: 600; font-size: 15pt"
							>{{ $t("Customer.EditDependent.Edit_Dependent") }}</span
							>
						</div>
					</div>
				</div>
				<div class="PageClass" style=" display:flex; flex-direction:column; padding: 10px 20px !important;height: 90vh; overflow: auto; margin-top:25px;" v-if="!isLoading">
					<p style="font-size:85%">{{$t("Customer.EditDependent.Valid_dependents_are_minors(Age<18)_and_elder_citizens(Age>60)") }}</p>
					<div>
		        <p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Relationship") }}<span style="color:red">*</span></p>
		        <v-select
							v-if="$i18n.locale=='en'"
							:items="Relationship_list"
							v-model="dependent_relationship"
							placeholder="Relationship"
							style="width: 100%;
							border-radius: 10px;
							margin-right: 10px;
							margin-left: 0px;
							margin-top: 7px;"
							outlined
							item-text = "en_text"
							return-object
							background-color="#F4F6FA"
							:rules="relationshipRules"
							>
						</v-select>
					</div>
					<div>
						<v-select
						v-if="$i18n.locale=='mm'"
						:items="Relationship_list"
						v-model="dependent_relationship"
						placeholder="တော်စပ်မှု"
						style="width: 100%;
						border-radius: 10px;
						margin-right: 10px;
						margin-left: 0px;
						margin-top: 7px;"
						outlined
						item-text = "mm_text"
						return-object
						background-color="#F4F6FA"
						:rules="relationshipRulesMM"
						>
						</v-select>
					</div>
					<div>
	          <p style="text-align: left; padding-bottom:0px; margin-top:7px; font-weight: 600 !important;font-weight: 600 !important;">{{ $t("Customer.EditDependent.Name") }}<span style="color:red">*</span></p>
	          <v-text-field
	            v-model="dependent_name"
	            outlined
	            :placeholder="translation_enter_name"
	            style="width: 100%; border-radius: 10px;margin-top:10px;"
	            background-color="#F4F6FA"
	            :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
	          />
					</div>
					<div>
	          <p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Date_Of_Birth") }}<span style="color:red">*</span></p>
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="dependent_dob"
									:placeholder="translation_dob"
									background-color="#F4F6FA"
									:rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
									outlined
									v-bind="attrs"
									v-on="on"
									style="border-radius:8px;"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="dependent_dob"
								:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
								min="1900-01-01"
								full-width
								@change="get_age(dependent_dob), menu= false"
								style="400px !important"
							></v-date-picker>
						</v-menu>
					</div>
					<div>
	         	<p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Email") }}</p>
						<v-text-field
	            v-model="dependent_email"
	            outlined
	            :placeholder="translation_enter_email"
	            background-color="#F4F6FA"
	            style="  border-radius:10px; margin-right:10px; margin-top:10px"
	          	>
	          </v-text-field>
					</div>
					<div>
						<p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Phone_Number") }}</p>
	          <v-text-field
	            v-model="dependent_phoneNumber"
	            outlined
	            :placeholder="translation_enter_number"
							type="number"
	            background-color="#F4F6FA"
	            style=" border-radius:10px; margin-right:10px; margin-top:10px"
	            :rules="$i18n.locale =='en' ? mobileNumberRules : mobileNumberRulesMM"
	          	>
	          </v-text-field>
					</div>
					<div>
	          <p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Gender") }}<span style="color:red">*</span></p>
	          <v-select
							v-if="$i18n.locale=='en'"
	            :items="gender"
	            v-model="dependent_gender"
	            outlined
	            placeholder="Gender"
	            background-color="#F4F6FA"
							return-object
	            style="  border-radius:10px; margin-right:10px; margin-top:10px"
	            :rules = "genderRules"
	   					item-text = "en_text"
	              >
	          </v-select>
					</div>
					<div>
						<v-select
							v-if="$i18n.locale=='mm'"
		          :items="gender"
		          v-model="dependent_gender"
		          outlined
		          placeholder="ကျား/မ"
							return-object
		          background-color="#F4F6FA"
		          style=" border-radius:10px; margin-right:10px; margin-top:10px"
		          :rules = "genderRulesMM"
							item-text = "mm_text"
		          >
		        </v-select>
					</div>
					<div>
						<p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.NRC") }}</p>
	          <v-text-field
	            v-model="dependent_nrc"
	            outlined
	            :placeholder="translation_enter_nrc"
	            background-color="#F4F6FA"
	            style=" border-radius:10px; margin-right:10px; margin-top:10px"
	          	>
	          </v-text-field>
					</div>
	        <v-row>
	          <v-col>
		<p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Height") }}</p>
		<div style="display:flex; felx-direction:row;">
			<v-text-field
				v-model="heightinFt"
				type="number"
				outlined
				:placeholder="translation_feet"
				:rules="$store.state.locale == 'en' ? heightRules : heightRulesMM"
				style="width: 5%; border-radius: 10px;margin-top:10px;"
				background-color="#F4F6FA"
			/>
			<p style="margin-top:auto; margin-bottom: auto;padding: 0px 5px;">{{ $t("Customer.EditProfile.Feet") }}</p>
			<v-text-field
				v-model="heightinIn"
				type="number"
				outlined
				:placeholder="translation_inches"
				:rules="$store.state.locale == 'en' ? heightRulesIn : heightRulesInMM"
				style="width: 5%; border-radius: 10px;margin-top:10px;margin-left:5px"
				background-color="#F4F6FA"
			/>
			<p style="margin-top:auto; margin-bottom: auto;padding: 0px 5px;">{{ $t("Customer.EditProfile.Inches") }}</p>
			</div>
	          </v-col>
	        </v-row>
	        <v-row>
	          <v-col>
	            <p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Weight") }}</p>
	            <v-text-field
	              v-model="dependent_weight"
	              outlined
	              :placeholder="translation_in_lbs"
	              type="number"
	              background-color="#F4F6FA"
	              class="flexbox"
	              style="margin-top:10px;"
	             />
	          </v-col>
	        </v-row>

	        <v-row style="margin-top:7px;">
	          <v-col>
	            <p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Smoker") }}</p>
	            <v-select
				v-if="$i18n.locale=='en'"
				:items ="smokerValueList"
	            v-model="dependent_smoker"
	            outlined
	            class="flex-item"
	            background-color="#F4F6FA"
				item-text = "en_text"
	            style="margin-top:10px;"
				return-object
	            />
   			  <v-select
				v-if="$i18n.locale=='mm'"
				:items ="smokerValueList"
	            v-model="dependent_smoker"
	            outlined
	            class="flex-item"
	            background-color="#F4F6FA"
				item-text = "mm_text"
				style="margin-top:10px;"
				return-object
	         />
	          </v-col>
	        </v-row>
	        <v-row>
	          <v-col>
	          	<p style="text-align:left;padding-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditDependent.Alcoholic") }}</p>
	          	<v-select
				v-if="$i18n.locale=='en'"
				:items="alcoholicValueList"
				v-model="dependent_alcoholic"
				item-text = "en_text"
				outlined
				background-color="#F4F6FA"
				class="flexbox"
				style="margin-top:10px;"
				return-object
	             />
				<v-select
				v-if="$i18n.locale=='mm'"
				:items="alcoholicValueList"
				v-model="dependent_alcoholic"
				item-text = "mm_text"
				outlined
				background-color="#F4F6FA"
				class="flexbox"
				style="margin-top:10px;"
				return-object
				/>
				</v-col>
				</v-row>
				<v-btn color="primary" style="width:98%; height:48px; margin-top:7px; align:center; "
						@click="saveDependentInfo"  :disabled="isButtonClicked || !(dependent_relationship != '' && dependent_name != '' && dependent_gender !='' && dependent_dob !='') || ageLimitNotInRange">
							{{ $t("Customer.EditDependent.Edit_Dependent") }}
				</v-btn>
				</div>
				<div style="margin-top:10%" v-else>
					 <v-progress-circular
						indeterminate
						color="primary"
						></v-progress-circular>
				</div>
			</div>
		</v-app>
	</div>
</template>

<script>
	import NavBar from "../../../views/navbar.vue";
	import axios from "axios";
	import bottomnavbar from "./bottomnavbar.vue";
	import moment from "moment"
	export default {
		name: "EditProfile",
		data(){
			return{
				dependent_name: "",
				heightinIn:"",
				heightinFt:"",
				old_name: "",
				menu:false,
				dependent_email : "",
				dependent_phoneNumber : "",
				dependent_nrc : "",
				dependent_smoker : "",
				dependent_dob: "",
				dependent_weight: "",
				dependent_height: "",
				dependent_gender: "",
				dependent_alcoholic: "",
				submit_button: "",
				auth_error_enable : false,
				error_enable : false,
				gender:  [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
				Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
				dependent_relationship: "",
				customer_id: "",
				customer_name: "",
				mobileNumber: "",
				currentCustomer: "",
				editProfileSuccessModal: false,
				dependent_body: "",
				dependent_id: "",
				alcoholicValueList : [{"key":"non_drinker","mm_text":"အရက်လုံးဝမသောက်ပါ","en_text":"Non drinker"},{"key":"social_drinker","mm_text":"လူမှုရေးအရသောက်ပါသည်","en_text":"Social drinker"},{"key":"moderate_drinker","mm_text":"အလွန်အကြူးမသောက်ပါ","en_text":"Moderate drinker"},{"key":"alcoholic","mm_text":"အရက်စွဲနေပါသည်","en_text":"Alcoholic"}],
				smokerValueList : [{"key":"yes","mm_text":"သောက်သည်","en_text":"Yes"},{"key":"no","mm_text":"မသောက်ပါ","en_text":"No"}],
				isMobile : false,
				ageLimitModal : false,
        ageLimitNotInRange : false,
        isButtonClicked: false,
        relationshipRules: [(v) => !!v || "Relationship is required"],
        relationshipRulesMM: [(v) => !!v || "တော်စပ်ပုံထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        nameRules: [(v) => !!v || "Name is required"],
        nameRulesMM: [(v) => !!v || "အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				nrcRules: [(v) => !!v || "NRC is required"],
				genderRules: [(v) => !!v || "Gender is required"],
				genderRulesMM: [(v) => !!v || "ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				//weightRules: [(v) => !!v || "Weight is required"],
				emailRules: [(v) => !!v || "Email is required"],
				dobRules: [(v) => !!v || "DOB is required"],
				dobRulesMM: [(v) => !!v || "မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				heightRules: [
				(v) => (v && v <= 10) || "Height cannot be more than 10feet",
				],
				heightRulesMM: [
				(v) => (v && v <= 10) || "အရပ်သည် ၁၀ ပေ အောက်ဖြစ်သင့်ပါသည်။",
				],
				heightRulesIn : [v => v < 12 || 'Inches can not be greater than 12'],
				heightRulesInMM : [v => v <= 12 || 'လက်မသည် ၁၂ လက်မအောက်ဖြစ်ရပါမည်။'],
				weightRules: [
        (v) => !!v || "Weight is required",
        (v) => ((v && v.length <= 3) || v == '1000') || "Weight cannot be more than 1000 lbs",
      	],
      	weightRulesMM: [
	        (v) => !!v || "Weight is required",
	        (v) => (v && v.length <= 3) || "အလေးချိန်သည် ပေါင် ၁၀၀၀ ထပ်ပိုများသောပမာဏ ဖြည့်သွင်း၍မရပါ။",
	      ],
				mobileNumberRules: [
				v => v > 0 || 'Mobile number cannot be negative',
				v => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
				],
				mobileNumberRulesMM: [
        v => (v && v.length >=7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
        v => v > 0 || 'ဖုန်းနံပါတ်တွင် အနုတ် (-) ထည့်၍ မရပါ',
      ],
				isLoading:true,
				translation_enter_name: "Enter Name",
	      translation_dob: "Date Of Birth",
	      translation_enter_email: "Enter Email",
	      translation_enter_number: "Enter Phone Number",
	      translation_enter_nrc: "Enter NRC",
	      translation_in_lbs: "in lbs",
	      translation_feet: "feet",
	      translation_inches: "inches",
	      show_header: true,
			}
		},
		methods:{
				 get_age(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
	  this.ageLimitNotInRange = false;
    },
	      closeageLimitModal(){
               this.ageLimitModal =false;
            },
			closeDialogUnauthorized(){
				this.auth_error_enable = false;
				this.$router.push({
					name: "Login",
				});
			},
			closeDialogError(){
				this.error_enable = false;
			},
			redirectToMyFamily() {
				this.$router.go(-1);
			},
			closeEditProfileSuccessModal(){
				this.editProfileSuccessModal = false;
				this.$router.push({
					name: "MyFamily"
				});
			},
			saveDependentInfo()
			{
				this.isButtonClicked = true;
				this.dependent_height = this.heightinFt +"\'"+ this.heightinIn + "\"";
				var dependent_body = {
					dependentId: this.dependent_id,
					dependentName: this.dependent_name.trim(),
					dependentEmail : this.dependent_email.trim(),
					dependentPhoneNumber : this.dependent_phoneNumber,
					dependentNrc : this.dependent_nrc,
					dependentCustomerRelation: this.dependent_relationship.en_text,
					dependentDob: this.dependent_dob,
					dependentHeight: this.dependent_height,
					dependentWeight: this.dependent_weight,
					dependentGender: this.dependent_gender.en_text,
					dependentSmoker : this.dependent_smoker ? this.dependent_smoker.en_text:"NA",
					dependentAlcoholic : this.dependent_alcoholic ? this.dependent_alcoholic.en_text:"NA",
					token : this.currentCustomer,
					typeOfUser : "CUSTOMER",
					name_changed: this.old_name.trim() != this.dependent_name.trim() ? true : false
				};
		    axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/updateDependentForCustomerId",
          dependent_body
        )
        .then((saveDependentInfoResponse) => {
          var dependent_object = saveDependentInfoResponse.data.data;
          this.editProfileSuccessModal = true;
        })
        .catch((saveDependentInfoError) => {
          if(saveDependentInfoError.response.status == 401){
            this.auth_error_enable = true;
            this.isButtonClicked = false;
          }
          else{
            this.error_enable = true;
            this.isButtonClicked = false;
          }
        });
			}
		},
		mounted(){
			var userAgent = navigator.userAgent
	    if(userAgent.includes("kbzpay")){
	      this.show_header = false;
	    }
			this.isMobile = this.$device.mobile;
			if(this.$store.state.locale == 'en')
				document.title = "Edit Dependent"
			else
				document.title = 'မှီခိုမိသားစုဝင်ကို ပြင်ဆင်ခြင်း'
			this.$i18n.locale = this.$store.state.locale;
			if(this.$i18n.locale == "en")
			{
				this.translation_enter_name = "Enter Name";
				this.translation_dob = "Enter Date Of Birth";
				this.translation_enter_email = "Enter Email";
				this.translation_enter_number = "Enter Phone Number";
				this.translation_enter_nrc = "Enter NRC";
				this.translation_in_lbs = "in lbs";
				this.translation_feet = "feet";
				this.translation_inches = "inches";
			}
				else
			{
				this.translation_enter_name = "အမည်ထည့်မည်";
		    this.translation_dob = "မွေးနေ့";
		    this.translation_enter_email = "အီးလ်မေး";
		    this.translation_enter_number = "ဖုန်းနံပါတ်";
		    this.translation_enter_nrc = "မှတ်ပုံတင်";
		    this.translation_in_lbs = "ပေါင်";
		    this.translation_feet = "ပေ";
		    this.translation_inches = "လက်မ";
			}
			this.currentCustomer = this.$cookies.get("customerToken");
			var access = this.$cookies.get("access");
			if(!this.currentCustomer){
				this.$router.push({
					name : "Login"
				})
			}
			else{
				// var verifyCustomerTokenData = {
				// 	"token" : this.currentCustomer,
				// 	"access" : access,
				// 	"typeOfUser" : "CUSTOMER"
				// }
				// axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
				// .then((verifyCustomerTokenDataResponse) => {
				// 	this.customer_name = verifyCustomerTokenDataResponse.data.data.customer_name;
					var params = this.$route.params;
					this.dependent_id = params["dependent_id"];
					var fetchDependentBody = {
						'dependent_id': this.dependent_id,
						'token': this.currentCustomer,
						'typeOfUser': 'CUSTOMER'
					};
					axios.post(process.env.VUE_APP_BACKEND_URL+"/fetchParticularDependent",fetchDependentBody)
					.then((viewParticularDependentResponse) => {
						this.dependent_object = viewParticularDependentResponse.data.data;
						this.dependent_name = this.dependent_object.dependent_name;
						this.dependent_email = this.dependent_object.dependent_email;
						this.dependent_nrc = this.dependent_object.dependent_nrc;
						this.dependent_phoneNumber = this.dependent_object.dependent_phoneNumber;
						var dependent_relationship = this.dependent_object.dependent_customer_relationship;
						let obj = this.Relationship_list.find(o => o.key === dependent_relationship.replace(" ","_").toLowerCase());
						this.dependent_relationship = obj;
						this.dependent_dob = moment(this.dependent_object.dependent_dob).format('YYYY-MM-DD');
						this.heightinFt = this.dependent_object.dependent_height.split("\'")[0] != "0" ? this.dependent_object.dependent_height.split("\'")[0] : "";
						this.heightinIn = this.dependent_object.dependent_height.split("\'")[1].split("\"")[0] != "0" ? this.dependent_object.dependent_height.split("\'")[1].split("\"")[0] : "";
						//this.dependent_height = this.dependent_object.dependent_height;
						this.dependent_weight = this.dependent_object.dependent_weight;

						var dependentGender = this.dependent_object.dependent_gender;
						let Genderobj = this.gender.find(o => o.key === dependentGender.replace(" ","_").toLowerCase());
						this.dependent_gender = Genderobj;

						var dependentSmoker = this.dependent_object.dependent_smoker;
						let Smokerobj = this.smokerValueList.find(o => o.key === dependentSmoker.replace(" ","_").toLowerCase());
						this.dependent_smoker = Smokerobj;

						var dependentAlcoholic = this.dependent_object.dependent_alcoholic;
						let Alcoholicobj = this.alcoholicValueList.find(o => o.key === dependentAlcoholic.replace(" ","_").toLowerCase());
						this.dependent_alcoholic = Alcoholicobj;
						this.old_name = this.dependent_name;
						this.isLoading = false
					})
					.catch((viewParticularDependentError) => {
						this.isLoading = false
						alert(viewParticularDependentError);
					});
				// })
				// .catch((verifyCustomerTokenDataError)=>{
				// 	if(verifyCustomerTokenDataError.response.status == 401){
				// 		this.auth_error_enable = true;
				// 		this.isLoading = false
				// 	}
				// 	else{
				// 		this.error_enable = true;
				// 		this.isLoading = false;
				// 	}
				// })
    		}
		},
	}
</script>

<style scoped>
/* .v-picker--full-width {
  height: 500px;
} */
/* @media (min-width: 800px){
  .mainDiveditDependent{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
 .PageClass{
	 margin-top: 25px !important;
 }
  } */
</style>
<style>
.v-select__selection {
  line-height: 2.2 !important;
}
.v-select__slot.v-select__selection.v-select__selection--comma{
  line-height: 2.2 !important;
}
.v-list-item .v-list-item__title{
	line-height: 2.2 !important;
}
</style>
